<template>
    <div class="container">
        <div class="block-404">
            <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="960px" height="560px" viewBox="0 0 960 560" enable-background="new 0 0 960 560" xml:space="preserve" p-id="3"><path fill="#EEEEEE" d="M233.483,208.48c0,0,0.422-0.047,0.656,0.234c0.234,0.281,0.516,4.218,1.547,5.296
            c1.031,1.078,5.203,0.469,5.203,1.875c0,1.125-3.877,1.246-5.189,2.324c-1.312,1.078-0.562,5.624-2.17,5.457
            c-1.259-0.131-0.375-3.75-1.922-5.203s-5.296-1.172-5.296-2.484s3.843-0.516,5.156-2.015
            C232.78,212.464,232.218,208.48,233.483,208.48z" p-id="4"></path><path fill="#B1AFAE" d="M376.804,222.306c0,0,0.255-0.028,0.396,0.141s0.311,2.546,0.934,3.197c0.622,0.651,3.14,0.283,3.14,1.132
            c0,0.679-2.34,0.752-3.132,1.403s-0.339,3.395-1.309,3.293c-0.76-0.079-0.226-2.263-1.16-3.14c-0.934-0.877-3.197-0.707-3.197-1.499
            c0-0.792,2.32-0.311,3.112-1.216C376.379,224.711,376.04,222.306,376.804,222.306z" p-id="5"></path><path fill="#EEEEEE" d="M727.965,219.204c0,0,0.289-0.032,0.45,0.161s0.353,2.891,1.06,3.63c0.707,0.739,3.566,0.321,3.566,1.285
            c0,0.771-2.657,0.854-3.557,1.593c-0.9,0.739-0.385,3.855-1.487,3.74c-0.863-0.09-0.257-2.57-1.317-3.566
            c-1.06-0.996-3.63-0.803-3.63-1.703s2.634-0.353,3.534-1.381C727.483,221.935,727.098,219.204,727.965,219.204z" p-id="6"></path><path fill="#B1AFAE" d="M594.571,211.794c0,0,0.289-0.032,0.45,0.161s0.353,2.891,1.06,3.63c0.707,0.739,3.566,0.321,3.566,1.285
            c0,0.771-2.657,0.854-3.557,1.593c-0.9,0.739-0.386,3.855-1.487,3.74c-0.863-0.09-0.257-2.57-1.317-3.566
            c-1.06-0.996-3.63-0.803-3.63-1.703s2.634-0.353,3.534-1.381C594.089,214.525,593.704,211.794,594.571,211.794z" p-id="7"></path><circle fill="#EEEEEE" cx="401.1" cy="183.965" r="4.203" p-id="8"></circle><circle fill="#EEEEEE" cx="732.584" cy="299.094" r="2.634" p-id="9"></circle><circle fill="#FFFFFF" cx="469.913" cy="293.441" r="86.028" p-id="10"></circle><path fill="#EEEEEE" d="M469.913,379.469c28.044,0,52.877-13.696,68.215-34.751l-39.807-94.67h-27.307h-2.204h-27.307l-39.807,94.67
            C417.036,365.774,441.869,379.469,469.913,379.469z" p-id="11"></path><path fill="#FDFDFD" d="M469.913,379.469c20.08,0,38.512-7.028,52.997-18.742l-30.408-109.613h-21.713h-1.753h-21.713
            l-30.408,109.613C431.401,372.441,449.832,379.469,469.913,379.469z" p-id="12"></path><path fill="#EEEEEE" d="M470.315,251.114h-0.804h-9.959l-14.518,123.922c0.051,0.244,0.105,0.481,0.156,0.723
            c8.203,2.964,15.897,3.71,24.724,3.71c8.827,0,16.479-0.079,24.724-3.71c0.052-0.242,0.105-0.48,0.156-0.723l-14.518-123.922
            H470.315z" p-id="13"></path><path fill="#D8D8D8" d="M513.965,229.357c-0.322-23.854-19.748-43.094-43.678-43.094c-23.93,0-43.356,19.24-43.677,43.094H513.965z" p-id="14"></path><rect x="426.595" y="229.133" fill="#FFFFFF" width="87.385" height="7.182" p-id="15"></rect><path fill="#D8D8D8" d="M518.769,236.296h-96.962c-5.454,0-9.876,4.422-9.876,9.876s4.421,9.876,9.876,9.876h8.875
            c-0.001,0.032-0.009,0.061-0.009,0.094v3.367c0,2.107,1.708,3.816,3.816,3.816s3.816-1.708,3.816-3.816v-3.367
            c0-0.032-0.009-0.062-0.009-0.094h63.987c-0.001,0.032-0.009,0.061-0.009,0.094v3.367c0,2.107,1.708,3.816,3.816,3.816
            s3.816-1.708,3.816-3.816v-3.367c0-0.032-0.009-0.062-0.009-0.094h8.875c5.454,0,9.876-4.422,9.876-9.876
            S524.223,236.296,518.769,236.296z" p-id="16"></path><g p-id="17"><circle fill="#FDFDFD" cx="450.404" cy="246.415" r="3.964" p-id="18"></circle><circle fill="#FDFDFD" cx="470.288" cy="246.415" r="3.964" p-id="19"></circle><circle fill="#FDFDFD" cx="490.172" cy="246.415" r="3.964" p-id="20"></circle></g><g p-id="21"><g p-id="22"><path fill="#D8D8D8" d="M352.619,327.549h-17.822v38.158h-17.103v-38.158h-64.989v-12.055l61.659-96.818h20.433v94.161h17.822
            V327.549z M317.694,312.837v-61.604c0-4.366,0.12-9.396,0.36-15.092h-0.36c-0.841,2.405-2.61,6.17-5.311,11.295l-41.496,65.4
            H317.694z" p-id="23"></path></g><g p-id="24"><path fill="#D8D8D8" d="M687.12,327.549h-17.822v38.158h-17.103v-38.158h-64.989v-12.055l61.659-96.818h20.433v94.161h17.822
            V327.549z M652.195,312.837v-61.604c0-4.366,0.12-9.396,0.36-15.092h-0.36c-0.841,2.405-2.61,6.17-5.311,11.295l-41.496,65.4
            H652.195z" p-id="25"></path></g></g></svg>

            <div class="msg">404！抱歉，您查看的页面不存在～<router-link to="/home">去首页</router-link></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped lang="less">
.container{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f4f5f7;
    display: flex;
    justify-content: center;

    .block-404 {    
        padding-top: 20%;
        font-size: 14px;
        color: #666;
        text-align: center;

        svg {
            width: 70vw;
            height: 70vw;
        }
        .msg {
            white-space: nowrap;
            margin-top: -25%;
            a {
                color: #1989fa;
                &:active {
                    color: #0456a9;
                }
            }
        }
    }   
}

</style>
